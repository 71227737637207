<template>
    <div class="podcast-epiosde-page">
        <Tier background="grey" class="stream-tier">
            <div class="podcast-hero">
                <!-- <div class="hero-col podcast-image">
                    <ImageBlock v-if="hero" :image="hero.image" type="actual" :noCaption="true" />
                </div> -->
                <div class="hero-col podcast-summary">
                    <PublicationSummary
                        subtype="Podcast"
                        :attributions="attributions"
                        :title="title"
                        :contributors="contributors"
                        :pdfResources="pdfResources"
                        :showReadTime="false"
                        :publishedAt="publishedAt"
                        :pageLanguage="pageLanguage"
                    />

                    <div class="podcast-embed" v-html="embedCode" />
                </div>
            </div>
        </Tier>

        <Publication :streamBlocks="streamContained" :tags="combinedTags">
            <template #main-left>
                <AsideBlock :hasPadding="false">
                    <AffiliationCard
                        v-for="item in affiliationContent"
                        :key="item.reference.path"
                        :heading="item.reference.title"
                        :dek="item.reference.dek || item.reference.about"
                        headingElement="h3"
                        :image="item.image"
                        :logoImage="item.logoImage"
                        :url="item.reference.path"
                        :eyebrow="$t(item.eyebrow)"
                        class="card"
                        :pageLanguage="pageLanguage"
                    />
                </AsideBlock>

                <AsideBlock
                    v-if="subscribeOn"
                    :heading="
                        $t('subscribe on', 1, {
                            locale: pageLanguage,
                        })
                    "
                >
                    <Button
                        v-for="(item, index) in subscribeOn"
                        :key="index"
                        :url="item.subscribeLink?.url"
                        :newTab="isLinkExternal(item.subscribeLink)"
                        iconPosition="left"
                        variant="secondary"
                        class="subscribe-button podcast-subscribe"
                        :class="item.network"
                    >
                        {{ item.subscribeLink?.label ? item.subscribeLink?.label : $t(item.network) }}
                        <template #icon>
                            <Icon :name="item.network" />
                        </template>
                    </Button>
                </AsideBlock>

                <AsideBlock v-if="supplementalLinks?.length" :heading="$t('additional links')">
                    <LinkList :links="supplementalLinks?.map((link) => link.link)" class="supplemental-links" />
                </AsideBlock>
            </template>

            <template #main-right>
                <AsideBlock
                    v-if="contributors?.length && !hostOverrides"
                    :heading="
                        $t('hosted by', 1, {
                            locale: pageLanguage,
                        })
                    "
                >
                    <ul class="podcast-contributors">
                        <li v-for="person in contributors" :key="person.id" class="contributor">
                            <PersonListItem :person="person" :isInline="true" size="33" />
                        </li>
                    </ul>
                </AsideBlock>

                <AsideBlock
                    v-if="hostOverrides?.length"
                    :heading="
                        $t('hosted by', 1, {
                            locale: pageLanguage,
                        })
                    "
                >
                    <ul class="podcast-contributors">
                        <li v-for="person in hostOverrides" :key="person.id" class="contributor">
                            <PersonListItem :person="person" :isInline="true" size="33" />
                        </li>
                    </ul>
                </AsideBlock>

                <AsideBlock
                    v-if="guests?.length"
                    :heading="
                        $t('featuring', 1, {
                            locale: pageLanguage,
                        })
                    "
                >
                    <ul class="podcast-contributors">
                        <li v-for="person in guests" :key="person.id" class="contributor">
                            <PersonListItem :person="person" :isInline="true" size="33" />
                        </li>
                    </ul>
                </AsideBlock>
            </template>

            <template #footer>
                <RelatedContentTier
                    v-if="relatedContent?.length"
                    :attributionTitle="podcastTitle"
                    :content="relatedContent"
                    :pieceTitle="title"
                    :pageLanguage="pageLanguage"
                />
            </template>
        </Publication>
    </div>
</template>

<script setup>
const { getCenterTagsBySyndication } = useCenters();
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    contributors: {
        type: Array,
    },
    dek: {
        type: Object,
    },
    embedCode: {
        type: String,
    },
    guests: {
        type: Object,
    },
    hero: {
        type: Object,
    },
    hostOverrides: {
        type: Object,
    },
    parent: {
        type: Object,
    },
    publishedAt: {
        type: String,
    },
    relatedContent: {
        type: Array,
    },
    socialShareDescription: {
        type: Object,
    },
    streamContained: {
        type: Object,
    },
    supplementalLinks: {
        type: Object,
    },
    syndications: {
        type: Object,
    },
    tags: {
        type: Array,
    },
    title: {
        type: String,
    },
    pdfResources: {
        type: Array,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const attributions = computed(() => {
    return {
        title: props.parent?.value.parent.value.title,
        url: props.parent?.value.parent.value.path,
        logo: props.parent?.value.parent.value.attributionLogo,
    };
});
const subscribeOn = computed(() => {
    return {
        ...props.parent?.value?.podcastNetworks,
    };
});

const affiliationContent = getAffiliationContent(props.parent?.value.syndications);
const collectionContent = getCollectionContent(props.syndications);

const podcastTitle = computed(() => {
    return props.parent?.value.title;
});

const combinedTags = getCenterTagsBySyndication(props.syndications, props.tags);
const headerAttributesStore = useHeaderAttributesStore();
const { GTMCenter, GTMAuthors, GTMPrograms, GTMProjects, GTMRegions, GTMTopics } = useGTMContent(
    'syndication',
    props,
    affiliationContent,
    combinedTags,
    collectionContent,
);

const GTMLang = props.locale;
let GTMType = 'Podcast Episode';
if (props.parent.value.title.length) {
    GTMType = 'Podcast Episode - ' + props.parent.value.title;
}

onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: GTMType,
        Expert: GTMAuthors,
        'Taxonomy Tags Program': GTMPrograms,
        'Taxonomy Special Project': GTMProjects,
        'Taxonomy Tags Regions': GTMRegions,
        'Taxonomy Tags Topics': GTMTopics,
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'grey' });
});
</script>

<style lang="scss" scoped>
.podcast-hero {
    gap: var(--col-gutter);
    @include grid;

    @include media-query(phone) {
        display: block;
    }

    .hero-col {
        @include content-area;
    }

    // .podcast-image {
    //     grid-column: 1 / span 6;
    // }

    .podcast-summary {
        padding-top: vertical-space(2);
        grid-column: 1 / span 12;
    }
}

.podcast-embed {
    margin-top: vertical-space(1);
}

.podcast-contributors {
    margin-bottom: vertical-space(3);

    .contributor {
        margin-top: vertical-space(0.5);
    }
}

.subscribe-button {
    &:not(:last-child) {
        margin-bottom: vertical-space(1);
    }
}
</style>
